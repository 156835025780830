/* eslint-disable*/
import { useState, useEffect, useCallback } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { Parser } from "node-sql-parser";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import getInsight from "../../../../api/get-insight";
import getTraits from "../../../../api/get-traits";
import CustomAutoComplete from "../../../../components/CustomAutoComplete";
import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";
import insightModuleConfig from "../../../../config/insightModuleConfig";
import useNotifier from "../../../../hooks/useNotifier";
import useUserProfile from "../../../../hooks/useUserProfile";
import debounce from "../../../../utilities/debounce";
import { isEmptyString } from "../../../../utilities/formValidation";
import handleError from "../../../../utilities/handleError";
import useImmer from "../../../../utilities/useImmer";
import validateInsightsInfo from "../../CreateInsightContainer/helpers/validateInsightsInfo";

import useStyles from "./styles";

const NewInsight = ({
  eventsInfo,
  setEventsInfo,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  title,
}) => {
  const initialFocusedValues = {
    insightsName: false,
    description: false,
    inputTraits: false,
    outputTrait: false,
    customIndicator: false,
    sqlExpression: false,
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [focused, setFocused] = useImmer(initialFocusedValues);
  const [insightNameValidationState, setInsightNameValidationState] = useState(
    {}
  );
  const [traitsLoading, setTraitsLoading] = useState(false);
  const { user } = useUserProfile();

  const [eventOptions, setEventOptions] = useState([]);
  const [, setEventsLoading] = useState(false);

  const [initialEventOptions] = useState([]);
  const { addNotification } = useNotifier();

  const [, setSearchTraitValue] = useState("");
  const [, setTraitOptions] = useState([]);

  // const [initialTraitOptions, setInitialTraitOptions] =
  // useState([]);
  const [consentTemplateTypeValue, setConsentTemplateTypeValue] = useState([]);
  const [consentTemplateTypeInputValue, setConsentTemplateTypeInputValue] =
    useState(consentTemplateTypeValue?.title || "");
  const [outputTraitValue, setOutputTraitValue] = useState({});
  const [outputTraitInputValue, setOutputTraitInputValue] = useState(
    outputTraitValue?.title || ""
  );

  const { t } = useTranslation();
  const classes = useStyles();
  const { createInsightConstants } = insightModuleConfig;
  const initialErrorsState = {
    insightNameError: null,
    insightNameErrorState: "",
    insightDescriptionError: null,
    insightInputTraitsError: null,
    insightOutputTraitsError: null,
    sqlIndicatorError: null,
    sqlExpressionError: null,
  };
  const [errors, setErrors] = useState(initialErrorsState);
  const [, setParsedSql] = useState(null);
  const [isCorrectSql, setIsCorrectSql] = useState(true);

  const handleParse = async () => {
    const parser = new Parser();
    try {
      const ast = parser.astify(eventsInfo.sqlExpression);
      setParsedSql(JSON.stringify(ast, null, 2));
      setIsCorrectSql(true);
    } catch (error) {
      setIsCorrectSql(false);
      setParsedSql(`Error:${error.message}`);
    }
  };

  useEffect(async () => {
    if (!eventsInfo.isNewEvent && eventsInfo?.insightsId !== undefined) {
      try {
        setEventsLoading(true);
        const response = await getInsight(eventsInfo.insightsId);
        setEventsInfo(response.insightDetail, ...response.insightMappings);
      } catch (error) {
        handleError({
          error,
          handle404: false,
          addNotification,
        });
      } finally {
        setEventsLoading(false);
      }
    }
  }, [!eventsInfo.isNewEvent]);

  useEffect(() => {
    handleParse();
  }, [eventsInfo.sqlExpression]);
  const resetState = () => {
    setFocused(initialFocusedValues);
    setCurrentStep(1);
    setErrors(initialErrorsState);
    setInsightNameValidationState({});
  };

  const checkStep1Disabled = (errorMap) => {
    const requiredFields = ["insightsName", "description"];
    const errorValues = JSON.parse(JSON.stringify(errorMap));
    const checkRequiredFields = requiredFields.filter((field) =>
      isEmptyString(eventsInfo[field])
    );
    if (
      errorValues.insightNameErrorState === applicationConfig.status.success
    ) {
      delete errorValues.insightNameErrorState;
      delete errorValues.insightNameError;
    }
    return !(
      Object.values(errorValues).filter((val) => val).length === 0 &&
      checkRequiredFields.length === 0
    );
  };

  const getTraitsFromApi = useCallback(async (searchText, page, perPage) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
        searchFields: "traitId,traitName,description",
      };
    }
    try {
      setTraitsLoading(true);
      const rsp1 = await getTraits(
        `?page=${page || 1}&itemsPerPage=${perPage || 6}&${new URLSearchParams(
          filter
        ).toString()}`
      );
      setEventOptions([...rsp1.items]);
      if (searchText.length === 0) {
        if (initialEventOptions.length > 0) {
          const newInitialEventOptions = [...initialEventOptions];
          newInitialEventOptions.splice(
            newInitialEventOptions.length - 1,
            0,
            ...rsp1.items
          );
          setTraitOptions(newInitialEventOptions);
        } else {
          setTraitOptions([...rsp1.items]);
        }
      }
    } catch (error) {
      // handleError({
      //   error,
      //   handle404: () => {
      //     setEventOptions([]);
      //   },
      //   addNotification,
      // });
    } finally {
      setEventsLoading(false);
      setTraitsLoading(false);
    }
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedEventsFromApi = debounce(
    getTraitsFromApi,
    applicationConfig.waitTime
  );

  const memoizedEventsFromApi = useCallback(async (val) => {
    debouncedEventsFromApi(val);
  }, []);

  useEffect(() => {
    if (eventsInfo?.inputTraits?.length > 0) {
      memoizedEventsFromApi(eventsInfo?.inputTraits);
    }
  }, [eventsInfo?.inputTraits]);

  useEffect(async () => {
    if (user.userId) {
      await getTraitsFromApi("");
    }
  }, [user]);

  const inputTraitsOptions = (inputOptions) => {
    const outputTraitsVal = outputTraitValue
      ? eventsInfo.outputTrait
      : eventsInfo.outputTrait;
    return inputOptions.filter(
      (value) => value?.traitId !== outputTraitsVal?.traitId
    );
  };

  const outputTraitsOptions = (outputOptions) => {
    const inputTraitsVal = consentTemplateTypeValue
      ? eventsInfo.inputTraits
      : eventsInfo.inputTraits;
    const inputTraitsValIds =
      (inputTraitsVal?.length > 0 &&
        inputTraitsVal.map((item) => item.traitId)) ||
      [];
    const modifyOutputOptions = outputOptions.filter(
      (item1) => !inputTraitsValIds.includes(item1.traitId)
    );
    return modifyOutputOptions;
  };

  // eslint-disable-next-line consistent-return
  const modifyOutputTrait = (outputTraitsValue) => {
    if (outputTraitsValue?.title) {
      return outputTraitsValue;
    }
    // if (Object.keys(outputTraitsValue).length !== 0) {
    //   // eslint-disable-next-line no-return-assign, no-param-reassign
    //   return (outputTraitsValue.title = `${outputTraitsValue?.traitId}-${outputTraitsValue?.traitName}`);
    // }
  };

  // eslint-disable-next-line consistent-return
  const displayInputTrait = (inputTraitsVal) => {
    if (inputTraitsVal?.length > 0 && inputTraitsVal[0].title) {
      return inputTraitsVal;
    }
    // if (inputTraitsVal?.length > 0) {
    //   inputTraitsVal.forEach((item) => {
    //     // eslint-disable-next-line no-param-reassign
    //     item.title = `${item?.traitId}-${item?.traitName}`;
    //   });
    //   return inputTraitsVal;
    // }
  };
  const newInsightFields = [
    {
      label: t("common.labels.insight_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      required: true,
      props: {
        placeholder: t("insight_container.placeholders.insight_name"),
        onChange: (insight) => {
          setEventsInfo((draft) => {
            draft.insightsName = insight.target.value;
          });
          setInsightNameValidationState({
            message: "",
          });
        },
        error:
          insightNameValidationState.state === "error" ||
          errors.insightNameError !== null,
        value: eventsInfo.insightsName || eventsInfo.insightName,
        helperText: (
          <InlineMessage
            message={
              errors.insightNameError || insightNameValidationState.message
            }
            state={insightNameValidationState.state}
          />
        ),
        onBlur: () => {
          setFocused((draft) => {
            draft.insightsName = true;
          });
        },
        onFocus: () => {
          setInsightNameValidationState({});
          setFocused((draft) => {
            draft.insightsName = false;
          });
        },
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.insight_description"),
      type: applicationConfig.inputType.textInput,
      required: true,
      props: {
        placeholder: t("insight_container.placeholders.insight_description"),
        onChange: (insight) =>
          setEventsInfo((draft) => {
            draft.description = insight.target.value;
          }),
        error: errors.insightDescriptionError !== null,
        value: eventsInfo.description,
        helperText: <InlineMessage message={errors.insightDescriptionError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.description = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.description = false;
          });
        },
        inputProps: {
          "data-testid": "insight-description",
        },
        rows: 2,
        multiline: true,
        disabled: isDisabled,
      },
    },

    {
      type: applicationConfig.inputType.custom,
      element: CustomAutoComplete,
      required: true,
      label: t("common.labels.input_traits"),
      props: {
        isMultiple: true,
        hideCheckbox: true,
        disableCloseOnSelect: false,
        disableFilterOptions: true,
        id: "input-traits-id",
        loading: traitsLoading,
        placeholder: traitsLoading
          ? t("common.loading")
          : t("new_trait.select_option"),

        options: traitsLoading
          ? []
          : inputTraitsOptions(eventOptions)
              .slice(0, Math.min(3, eventOptions.length))
              .map((type) => ({
                ...type,
                title: `${type.traitId} - ${type.traitName}`,
              })),
        value:
          displayInputTrait(
            consentTemplateTypeValue
              ? eventsInfo.inputTraits
              : eventsInfo.inputTraits
          ) || [],
        inputValue:
          consentTemplateTypeInputValue || eventsInfo.inputTraits?.title,
        setValue: (value) => {
          setConsentTemplateTypeValue(value);
          setConsentTemplateTypeInputValue(value?.title);
          setEventsInfo((draft) => {
            draft.inputTraits = value;
          });
        },
        error: errors.insightInputTraitsError !== null,
        errorText: errors.insightInputTraitsError,
        onInputChange: (val) => {
          setTraitOptions([]);
          setSearchTraitValue(val);
          setConsentTemplateTypeInputValue(val);
          memoizedEventsFromApi(val);
        },
        disabled: isDisabled,
      },
    },

    {
      type: applicationConfig.inputType.custom,
      element: CustomAutoComplete,
      required: true,
      label: t("common.labels.output_traits"),
      props: {
        isMultiple: false,
        hideCheckbox: true,
        disableCloseOnSelect: false,
        disableFilterOptions: true,
        id: "output-trait-id",
        loading: traitsLoading,
        placeholder: traitsLoading
          ? t("common.loading")
          : t("new_trait.select_option"),
        options: traitsLoading
          ? []
          : outputTraitsOptions(eventOptions)
              .slice(0, Math.min(3, eventOptions.length))
              .map((type) => ({
                ...type,
                title: `${type.traitId} - ${type.traitName}`,
              })),
        value: modifyOutputTrait(
          outputTraitValue ? eventsInfo.outputTrait : eventsInfo.outputTrait
        ), // outputTraitValue,
        inputValue:
          outputTraitInputValue ||
          eventsInfo.outputTrait?.title ||
          eventsInfo.outputTrait,
        setValue: (value) => {
          setOutputTraitValue(value);
          setOutputTraitInputValue(value?.title);
          setEventsInfo((draft) => {
            draft.outputTrait = value;
          });
        },
        error: errors.insightOutputTraitsError !== null,
        errorText: errors.insightOutputTraitsError,
        onInputChange: (val) => {
          // setTraitOptions([]);
          // setSearchTraitValue(val)
          setOutputTraitInputValue(val);
          memoizedEventsFromApi(val);
        },
      },
    },

    {
      label: t("common.labels.sql_custom_indecator"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        // label: !eventsInfo.customIndicator ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (insight) =>
          setEventsInfo((draft) => {
            draft.customIndicator = insight.target.value;
          }),
        inputProps: {
          "data-testid": "sql_custom_indecator",
        },
        values: createInsightConstants.sql_indicator.map((option) => ({
          label: option,
          value: option,
        })),
        value:
          eventsInfo.customIndicator,
          //  || eventsInfo.isSQLTrait === "true"
          //   ? "Yes"
          //   : "No",
        disabled: isDisabled,
        helperText: focused.customIndicator && (
          <InlineMessage message={errors.sqlIndicatorError} />
        ),
      },
    },
    {
      label: t("common.labels.sql_expression"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("insight_container.placeholders.sql_expression"),
        onChange: (insight) => {
          setEventsInfo((draft) => {
            draft.sqlExpression = insight.target.value;
          });
          setIsCorrectSql(true);
        },
        value: eventsInfo.sqlExpression,
        inputProps: {
          "data-testid": "sql-expression",
        },
        // type: "number",
        rows: 2,
        multiline: true,
        disabled: isDisabled,
        helperText: !isCorrectSql && (
          <InlineMessage message="incorrect query" />
        ),
      },
    },
  ];
  useEffect(() => {
    const errors1 = validateInsightsInfo(eventsInfo, focused, isDisabled);
    setErrors(errors1);
  }, [eventsInfo, focused]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">
            {!isDisabled
              ? title
              : `${t("insight_container.new_insight")} - ${
                  eventsInfo.insightsName || eventsInfo.insightName
                }`}
          </Typography>
          <div
            className={classes.marginLeftAuto}
            onClick={() => {
              handleClose();
              resetState();
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        {currentStep === 1 && (
          <DialogContent>
            <Form
              fields={newInsightFields}
              fieldClassName={clsx(
                classes.fieldContainer,
                classes.flexContainer
              )}
            />
          </DialogContent>
        )}
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          {!(currentStep === 1 && isDisabled) && (
            <div className={classes.btnContainer}>
              <Button
                onClick={() => {
                  if (currentStep === 1) {
                    if (!isDisabled) {
                      setIsCancelClicked(true);
                    } else {
                      handleClose();
                    }
                  } else {
                    setCurrentStep(currentStep - 1);
                  }
                }}
                color="primary"
                variant={
                  currentStep === 2 && isDisabled ? "contained" : "outlined"
                }
              >
                {currentStep === 1 ? t("common.cancel1") : t("common.previous")}
              </Button>
            </div>
          )}
          {!(currentStep === 2 && isDisabled) && !isDisabled && (
            <div className={classes.btnContainer}>
              <Button
                onClick={async () => {
                  //  await handleParse();

                  setInsightNameValidationState({});
                  const allFocusedValues = {};
                  Object.keys(initialFocusedValues).forEach((key) => {
                    allFocusedValues[key] = true;
                  });

                  setFocused(allFocusedValues);
                  const errors1 = validateInsightsInfo(
                    eventsInfo,
                    allFocusedValues,
                    isDisabled
                  );

                  if (Object.values(errors1).some((x) => x)) {
                    setErrors(errors1);
                  }
                  // if (!Object.values(errors1).some((x) => x) && !isDisabled) {
                  //   const isInValid = await searchForEvent();
                  //   if (isInValid) {
                  //     return;
                  //   }
                  // }
                  if (checkStep1Disabled(errors1)) {
                    return;
                  }
                  if (!isCorrectSql) {
                    return;
                  }

                  handleSubmit(eventsInfo);
                  resetState();
                }}
                color="primary"
                variant="contained"
              >
                {currentStep === 1 &&
                  insightNameValidationState.state === "info" && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.5,
                      }}
                    >
                      <CircularProgress size={20} color="white" />
                    </Box>
                  )}
                {!isDisabled && t("common.submit")}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewInsight.defaultProps = {
  eventsInfo: {},
};

NewInsight.propTypes = {
  eventsInfo: PropTypes.shape({
    insightsName: PropTypes.string,
    description: PropTypes.string,
    inputTraits: PropTypes.string,
    outputTrait: PropTypes.string,
    customIndicator: PropTypes.string,
    sqlExpression: PropTypes.string,
    isNewEvent: PropTypes.bool.isRequired,
    insightsId: PropTypes.string,
    insightName: PropTypes.string,
    isSQLTrait: PropTypes.bool.isRequired,
  }),
  setEventsInfo: PropTypes.func.isRequired,
  // eventsSelected: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     title: PropTypes.string.isRequired,
  //     isSelected: PropTypes.bool.isRequired,
  //     isNewEvent: PropTypes.bool.isRequired,
  //     [PropTypes.string]: PropTypes.string,
  //   })
  // ).isRequired,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewInsight;
