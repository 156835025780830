import insightModuleConfig from "../../../../config/insightModuleConfig";
import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateEqualStrings,
  validateMinimumCharactersCount,
  validateMaximumCharactersCount,
  validateMinimumNonSpaceWords,
} from "../../../../utilities/formValidation";

const { createInsightConstants } = insightModuleConfig;

const validateInsightsInfo = (insightsInfo, focused, isDisabled) => {
  const errors = {
    insightNameError: null,
    insightNameErrorState: "",
    insightDescriptionError: null,
    insightInputTraitsError: null,
    insightOutputTraitsError: null,
    sqlIndicatorError: null,
    sqlExpressionError: null,
  };
  if (isDisabled) {
    return errors;
  }
  // Required field checks
  if (isEmptyString(insightsInfo.insightsName) && focused.insightsName) {
    errors.insightNameError = i18n.t("errors.field_required");
  }
  if (isEmptyString(insightsInfo.description) && focused.description) {
    errors.insightDescriptionError = i18n.t("errors.field_required");
  }
  if (isEmptyString(insightsInfo.outputTrait) && focused.outputTrait) {
    errors.insightOutputTraitsError = i18n.t("errors.field_required");
  }
  if (isEmptyString(insightsInfo.inputTraits) && focused.inputTraits) {
    errors.insightInputTraitsError = i18n.t("errors.field_required");
  }
  // Individual field checks
  if (!isEmptyString(insightsInfo.insightsName) && focused.insightsName) {
    errors.insightNameErrorState = "";
    // if (!validateAlphaNumericCharactersWithSpace(insightsInfo.insightsName)) {
    //   errors.insightNameError = i18n.t("common.errors.only_letters_numbers");
    // } else
    if (
      !validateMaximumCharactersCount(
        insightsInfo.insightsName,
        createInsightConstants.characterLimits.insightsName
      )
    ) {
      errors.insightNameError = i18n.t("common.errors.character_limit_error", {
        count: createInsightConstants.characterLimits.insightsName,
      });
    }
  }
  if (!isEmptyString(insightsInfo.description) && focused.description) {
    if (
      validateEqualStrings(insightsInfo.insightsName, insightsInfo.description)
    ) {
      errors.insightDescriptionError = i18n.t(
        "insight_container.errors.insight_description.error_1"
      );
    } else if (!validateMinimumCharactersCount(insightsInfo.description, 5)) {
      errors.insightDescriptionError = i18n.t(
        "insight_container.errors.insight_description.error_2"
      );
    } else if (!validateMinimumNonSpaceWords(insightsInfo.description, 3)) {
      errors.insightDescriptionError = i18n.t(
        "insight_container.errors.insight_description.error_3"
      );
    }
  }

  return errors;
};

export default validateInsightsInfo;
