import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import useGlobalStyles from "../../../../assets/styles/global";
import { AccordionModalDetails } from "../../../../components/AccordionModal";
import { KeyValueSeparator } from "../../../../components/AccordionRowDetails";
import CustomModal from "../../../../components/CustomModal";
import { MarketingPrograms } from "../../../../components/MarketingProgramsModal";
import applicationConfig from "../../../../config/applicationConfig";
import sourcesModuleConfig from "../../../../config/sourcesModuleConfig";
import { splitCamelCaseString } from "../../../../utilities/formValidation";
import isEmpty from "../../../../utilities/isEmpty";

import useStyles from "./styles";

const SourceIdModal = ({ title, subtitle, data, loading, open, onClose }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);

  const { marketingPrograms, ecoSystemSources } = data;

  const { ecosystemFeatureKeys } = sourcesModuleConfig;

  const rows = Object.keys(data)
    .filter(
      (item) => item !== "marketingPrograms" && item !== "ecoSystemSources"
    )
    .map((key) => {
      const label = splitCamelCaseString(key);
      if (isEmpty(data[key])) {
        return null;
      }
      return {
        label:
          label === "sourceId"
            ? "Source ID"
            : label[0].toUpperCase() + label.slice(1),
        value: data[key],
      };
    })
    .filter((val) => !!val);

  const removeSourceIds = (string) => {
    if (string.indexOf("("))
      return `${string.slice(0, string.indexOf("("))}...`;
    return string;
  };

  const sources = (ecoSystemSources || []).map((source) => ({
    ...source,
    ecosystemSourceTruncatedName: removeSourceIds(source.ecosystemSourceName),
  }));

  const renderUi = () => {
    if (tab === 0) {
      return (
        <table className={globalClasses.table}>
          <tbody>
            {rows.map((row, ind) => (
              <KeyValueSeparator
                keyVal={row.label}
                value={row.value}
                ind={ind.toString()}
                separator={null}
              />
            ))}
          </tbody>
        </table>
      );
    }
    if (tab === 1) {
      return (
        <MarketingPrograms
          type={applicationConfig.modules.data_sources}
          programs={marketingPrograms}
          loading={false}
        />
      );
    }
    return (
      <AccordionModalDetails
        keys={ecosystemFeatureKeys}
        rows={sources}
        emptyDataMessage={t("ecosystems.no_sources_mapping")}
        rowKeys={{
          title: "ecosystemName",
          subTitle: "",
          subTitle2: "ecosystemSourceTruncatedName",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      data={{
        sourceDetails: rows,
        marketingPrograms,
        ecosystemSources: sources,
      }}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
            }}
          >
            <Tab label="Source Details" />
            <Tab label="Marketing Programs" />
            <Tab label="Ecosystem Sources" />
          </Tabs>
          {renderUi()}
          <Button
            style={{ marginTop: "15px" }}
            variant="contained"
            disabled={loading}
            component={Link}
            to={{
              pathname: applicationConfig.pathnames.sources_manage,
              state: {
                dataSource: data,
              },
            }}
            onClick={() => {}}
            className={globalClasses.btn}
          >
            {t("disconnect_sources.disconnect_sources")}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

SourceIdModal.defaultProps = {
  title: null,
};

SourceIdModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    marketingPrograms: PropTypes.shape([]).isRequired,
    ecoSystemSources: PropTypes.shape([]).isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SourceIdModal;
