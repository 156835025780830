import i18n from "../../../../i18n/init";
import { isEmptyString } from "../../../../utilities/formValidation";

const validateMpMappingInfo = (mpMappingInfo, isDisabled) => {
  const errors = {
    marketingProgramError: null,
    statusError: null,
    computeFrequencyTypeError: null,
    batchScheduleError: null,
    processingTypeError: null,
    sourceProjectError: null,
    destinationProjectError: null,
  };

  if (isDisabled) {
    return errors;
  }

  // Marketing Program
  if (isEmptyString(mpMappingInfo.marketingProgram)) {
    errors.marketingProgramError = i18n.t("errors.field_required");
  }

  // Status
  if (isEmptyString(mpMappingInfo.status)) {
    errors.statusError = i18n.t("errors.field_required");
  }

  // Compute Frequency Type
  if (isEmptyString(mpMappingInfo.computeFrequencyType)) {
    errors.computeFrequencyTypeError = i18n.t("errors.field_required");
  }

  // Batch Schedule
  if (
    (mpMappingInfo.computeFrequencyType === "batch" ||
      mpMappingInfo.computeFrequencyType === "both") &&
    isEmptyString(mpMappingInfo.batchSchedule)
  ) {
    errors.batchScheduleError = i18n.t("errors.field_required");
  }

  // Processing Type
  if (isEmptyString(mpMappingInfo.processingType)) {
    errors.processingTypeError = i18n.t("errors.field_required");
  }

  return errors;
};

export default validateMpMappingInfo;
