import api from "../utilities/api";

const disconnectDataSourceMappings = async (body) => {
  const url = "/data-sources/mappings";
  const {
    data: { data },
  } = await api.post(url, body);
  return data;
};

export default disconnectDataSourceMappings;
