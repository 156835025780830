import { useState } from "react";

import { Button } from "@material-ui/core";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import ConfirmationModal from "../../../../../components/ConfirmationModal";

const MarketingPrograms = ({
  marketingPrograms,
  diffList,
  setDiffList,
  isDisabled,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rows, setRows] = useState(
    marketingPrograms.map((row) => {
      const diffListEntry = diffList.find(
        (dfRow) => dfRow.marketingProgramNumber === row.marketingProgramNumber
      );
      if (diffListEntry) {
        return diffListEntry;
      }
      return row;
    }) || []
  );
  console.log(rows);
  const [deletedItem, setDeletedItem] = useState();

  const { t } = useTranslation();

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const columns = [
    {
      field: "marketingProgramNumber",
      headerName: "Marketing Program Number",
      align: "center",
      headerAlign: "center",
      type: "number",
      flex: 1,
      sortable: false,
    },
    {
      field: "marketingProgramDescription",
      headerName: "Description",
      align: "center",
      headerAlign: "center",
      type: "string",
      flex: 1,
      sortable: false,
    },
    {
      field: "actions",
      hideable: false,
      type: "actions",
      flex: 1,
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Button
              style={{
                color: "#ffffff",
                padding: "5px 8px",
                fontSize: "12px",
                minWidth: "88px",
                textAlign: "center",
                borderRadius: "4px",
                backgroundColor: "#FF0000",
              }}
            >
              {isDisabled || params.row.isDisconnected
                ? "Disconnected"
                : "Disconnect"}
            </Button>
          }
          label="Disconnect"
          sx={{
            "& .MuiSvgIcon-root": {
              fill: "#FF7C7C !important",
            },
          }}
          onClick={() => {
            if (!isDisabled && !params.row.isDisconnected) {
              setDeletedItem(params);
              setOpenDeleteModal(true);
            }
          }}
        />,
      ],
    },
  ];
  return (
    <>
      <div style={{ height: 420, width: "100%" }}>
        {isDisabled && (
          <DataGrid
            columns={columns}
            rows={rows}
            getRowId={(row) => row.marketingProgramNumber}
          />
        )}
        {!isDisabled && (
          <DataGrid
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.marketingProgramNumber}
          />
        )}
      </div>
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_disconnect")}
        message={t("disconnect_sources.disconnect_mpn_confirmation")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.yes")}
        btn2Action={async () => {
          setTimeout(() => {
            setRows((prevRows) =>
              prevRows.map((row) => {
                if (row.marketingProgramNumber === deletedItem.id) {
                  return {
                    ...row,
                    isDisconnected: true,
                  };
                }
                return row;
              })
            );
            setDiffList([
              ...diffList,
              { ...deletedItem.row, isDisconnected: true },
            ]);
          });
          onCloseDeleteModal();
        }}
        type="success"
      />
    </>
  );
};

MarketingPrograms.defaultProps = {
  diffList: [],
  setDiffList: () => {},
  isDisabled: false,
};

MarketingPrograms.propTypes = {
  marketingPrograms: PropTypes.arrayOf(
    PropTypes.shape({
      marketingProgramNumber: PropTypes.number,
      description: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  diffList: PropTypes.array,
  setDiffList: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default MarketingPrograms;
