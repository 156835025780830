import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { setValue } = require("../utilities/cache");

const { requestIdPrefix } = applicationConfig;

const createSourceRequest = async (body) => {
  const url = `/data-sources`;
  const {
    data: { data },
  } = await api.post(url, body);

  if (data.items.some((item) => item.status === "approved")) {
    setValue("data-sources");
  }
  data.items = data.items.map((item) => ({
    ...item,
    requestId: `${requestIdPrefix}${item.requestId}`,
  }));
  return data;
};

export default createSourceRequest;
