import { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AutoComplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getInsightsFrequency from "../../../../api/get-insight-frequency";
import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";
import insightsModuleConfig from "../../../../config/insightModuleConfig";
import useMarketingPrograms from "../../../../hooks/useMarketingPrograms";
import validateMpMappingInfo from "../../CreateInsightContainer/helpers/validateMpMappingInfo";

import useStyles from "./styles";

const NewMpMapping = ({
  mpMappingInfo,
  setMpMappingInfo,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  title,
  selectedInsight,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getMarketingProgramsFromApi,
    marketingProgramOptions,
    marketingProgramsLoading,
  } = useMarketingPrograms();

  const initialErrorsState = {
    marketingProgramError: null,
    insightNameError: null,
    insightNameErrorState: "",
    insightDescriptionError: null,
    insightInputTraitsError: null,
    insightOutputTraitsError: null,
    sqlIndicatorError: null,
    sqlExpressionError: null,
  };
  const [errors, setErrors] = useState(initialErrorsState);
  const [frequency, setFrequency] = useState(
    Array.from({ length: 24 }, (_, i) => ({
      label: `${i + 1}:00`,
      value: `${i + 1}:00`,
    }))
  );

  const { sourceDetails, destinationDetails } = insightsModuleConfig;
  //   const [errors, setErrors] = useState({});

  // ... (other necessary state and effects)

  const resetState = () => {
    setErrors(initialErrorsState);
    // setFocused(initialFocusedValues);
  };

  const fetchFrequency = async () => {
    const frequencies = Array.from({ length: 24 }, (_, i) => ({
      label: `${i + 1}:00`,
      value: `${i + 1}:00`,
    }));
    try {
      const response = await getInsightsFrequency(selectedInsight[0].insightId);
      if (response?.invalidTimeSlots?.length) {
        const invalidTimeSlots = response?.invalidTimeSlots;
        const updatedFrequencies = frequencies.filter(
          (item) => !invalidTimeSlots.includes(item.label)
        );
        setFrequency(updatedFrequencies);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getMarketingProgramsFromApi("");
    fetchFrequency();
  }, []);
  const sourceOptions = [
    {
      value: "SQL Expression for BigQuery",
      label: "SQL Expression for BigQuery",
    },
    { value: "SQL Expression in Spanner", label: "SQL Expression in Spanner" },
  ];
  const destinationOptions = [
    { value: "CRM Activation", label: "CRM Activation" },
    { value: "Website Personalization", label: "Website Personalization" },
  ];

  const newMpMappingFields = [
    {
      type: applicationConfig.inputType.custom, // Change this to a standard type if needed
      element: AutoComplete, // Use standard Autocomplete
      required: true,
      label: t("insight_container.mp_mapping.marketing_program"),
      props: {
        id: "marketing-program-autocomplete",
        options: marketingProgramOptions,
        loading: marketingProgramsLoading,
        getOptionLabel: (option) => {
          if (!option || !option.marketingProgramNumber) return "";
          return `${option.marketingProgramNumber} - ${option.description}`;
        },
        onChange: (event, value) => {
          setMpMappingInfo((draft) => {
            draft.marketingProgram = value;
          });
          setErrors({
            ...errors,
            marketingProgramError: null,
          });
        },
        placeholder: t(
          "insight_container.mp_mapping.placeholders.marketing_program"
        ),
        value: mpMappingInfo.marketingProgram || "",
        disabled: isDisabled,
        helperText: <InlineMessage message={errors.marketingProgramError} />,
        renderInput: (params) => (
          <TextField
            {...params}
            // label={t("insight_container.mp_mapping.marketing_program")}
            variant="outlined"
            onChange={(event) => {
              getMarketingProgramsFromApi(event.target.value); // Ensure this function is defined
            }}
          />
        ),
      },
    },
    {
      label: t("insight_container.mp_mapping.status"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        onChange: (event) => {
          setMpMappingInfo((draft) => {
            draft.status = event.target.value;
          });
          setErrors({
            ...errors,
            statusError: null,
          });
        },
        values: [
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ],
        value: mpMappingInfo.status,
        disabled: isDisabled,
        placeholder: t("insight_container.mp_mapping.placeholders.status"),
        helperText: <InlineMessage message={errors.statusError} />,
      },
    },
    {
      label: t("insight_container.mp_mapping.compute_frequency"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        onChange: (event) => {
          setMpMappingInfo((draft) => {
            draft.computeFrequencyType = event.target.value;
          });
          setErrors({
            ...errors,
            computeFrequencyTypeError: null,
          });
        },
        values: [
          { label: "Realtime", value: "realtime" },
          { label: "Batch", value: "batch" },
          { label: "Both", value: "both" },
        ],
        value: mpMappingInfo.computeFrequencyType,
        disabled: isDisabled,
        placeholder: t(
          "insight_container.mp_mapping.placeholders.compute_frequency"
        ),
        helperText: (
          <InlineMessage message={errors.computeFrequencyTypeError} />
        ),
      },
    },
    {
      ...(mpMappingInfo.computeFrequencyType === "batch" ||
      mpMappingInfo.computeFrequencyType === "both"
        ? {
            label: t("insight_container.mp_mapping.batch_schedule"),
            type: applicationConfig.inputType.dropdown,
            required:
              mpMappingInfo.computeFrequencyType === "batch" ||
              mpMappingInfo.computeFrequencyType === "both",
            props: {
              select: true,
              variant: "outlined",
              onChange: (event) => {
                setMpMappingInfo((draft) => {
                  draft.batchSchedule = event.target.value;
                });
                setErrors({
                  ...errors,
                  batchScheduleError: null,
                });
              },
              values: frequency,
              value: mpMappingInfo.batchSchedule,
              disabled:
                isDisabled ||
                (mpMappingInfo.computeFrequencyType !== "batch" &&
                  mpMappingInfo.computeFrequencyType !== "both"),
              placeholder: t(
                "insight_container.mp_mapping.placeholders.batch_schedule"
              ),
              helperText: <InlineMessage message={errors.batchScheduleError} />,
            },
          }
        : {}),
    },
    {
      label: t("insight_container.mp_mapping.processing_type"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        onChange: (event) => {
          setMpMappingInfo((draft) => {
            draft.processingType = event.target.value;
          });
          setErrors({
            ...errors,
            processingTypeError: null,
          });
        },
        values: [
          { label: "Full", value: "full" },
          { label: "Delta", value: "delta" },
        ],
        value: mpMappingInfo.processingType,
        disabled: isDisabled,
        placeholder: t(
          "insight_container.mp_mapping.placeholders.processing_type"
        ),
        helperText: <InlineMessage message={errors.processingTypeError} />,
      },
    },

    {
      label: t("insight_container.mp_mapping.source_project"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        onChange: (item) =>
          setMpMappingInfo((draft) => {
            draft.selectedSourceValue = item.target.value;
            if (item.target.value === "SQL Expression for BigQuery") {
              draft.source.project = sourceDetails.bigQueryDetails.project;
              draft.source.instance = sourceDetails.bigQueryDetails.instance;
            } else {
              draft.source.project = sourceDetails.spannerDetails.project;
              draft.source.instance = sourceDetails.spannerDetails.instance;
            }
          }),
        inputProps: {
          "data-testid": "source_project",
        },
        values: sourceOptions.map((option) => ({
          label: option.label,
          value: option.label,
        })),
        value: mpMappingInfo.selectedSourceValue,
        disabled: isDisabled,
        helperText: <InlineMessage message={errors.sourceProjectError} />,
      },
    },

    {
      label: t("insight_container.mp_mapping.destination_project"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        onChange: (item) =>
          setMpMappingInfo((draft) => {
            draft.selectedDestinationValue = item.target.value;
            if (item.target.value === "CRM Activation") {
              draft.destination.project =
                destinationDetails.crmActivation.project;
              draft.destination.instance =
                destinationDetails.crmActivation.instance;
            } else {
              draft.destination.project =
                destinationDetails.websitePersonalization.project;
              draft.destination.instance =
                destinationDetails.websitePersonalization.instance;
            }
          }),
        inputProps: {
          "data-testid": "destination_project",
        },
        values: destinationOptions.map((option) => ({
          label: option.label,
          value: option.label,
        })),
        value: mpMappingInfo.selectedDestinationValue,
        disabled: isDisabled,
        helperText: <InlineMessage message={errors.destinationProjectError} />,
      },
    },
  ].filter((field) => Object.keys(field).length > 0);

  // ... (rest of the component logic)

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <div className={clsx(classes.flexContainer, classes.alignStart)}>
        <Typography variant="h5">{title}</Typography>
        <div
          className={classes.marginLeftAuto}
          onClick={handleClose}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
          data-testid="close-btn"
        >
          <Close />
        </div>
      </div>
      <DialogContent>
        <Form
          fields={newMpMappingFields}
          fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.justifySpaceBetween,
        }}
      >
        <div className={classes.btnContainer}>
          <Button
            onClick={() => {
              resetState();
              handleClose();
            }}
            color="primary"
            variant="outlined"
          >
            {t("common.cancel")}
          </Button>
        </div>
        <div className={classes.btnContainer}>
          <Button
            onClick={() => {
              const errors1 = validateMpMappingInfo(mpMappingInfo, isDisabled);

              if (Object.values(errors1).some((x) => x)) {
                setErrors(errors1);
                return;
              }

              resetState();
              handleSubmit(mpMappingInfo); // Assuming handleSubmit is passed as a prop
            }}
            color="primary"
            variant="contained"
            disabled={isDisabled} // Disable if the form is not valid
          >
            {t("common.submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

NewMpMapping.propTypes = {
  mpMappingInfo: PropTypes.object.isRequired,
  setMpMappingInfo: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  selectedInsight: PropTypes.object.isRequired,
};

export default NewMpMapping;
