/* eslint-disable */
// import { ecosystems } from "../i18n/english";
import i18n from "../i18n/init";

const deploymentEnv =
  process.env.NODE_ENV === "test"
    ? "dev"
    : window.env.DEPLOYMENT_ENV || process.env.REACT_APP_DEPLOYMENT_ENV;

const insightsModuleConfig = {
  createInsightConstants: {
    insightInputTraits: [
      i18n.t("constants.event_category.consumer_action"),
      i18n.t("constants.event_category.email_ctl"),
      i18n.t("constants.event_category.iot"),
      i18n.t("constants.event_category.loyalty"),
      i18n.t("constants.event_category.postal_CLT"),
      i18n.t("constants.event_category.push_CLT"),
      i18n.t("constants.event_category.replenishment"),
      i18n.t("constants.event_category.sms_CLT"),
      i18n.t("constants.event_category.sales_transaction"),
      i18n.t("constants.event_category.website_event"),
    ],
    isRequiredOptions: [
      {
        option: true,
        label: i18n.t("common.yes"),
      },
      {
        option: false,
        label: i18n.t("common.no"),
      },
    ],
    characterLimits: {
      insightsName: 50,
      insightDescription: 500,
      eventType: 2,
    },
    sql_indicator: ["Yes", "No"],
  },
  sourceDetails: {
    bigQueryDetails: {
      project:
        deploymentEnv === "local"
          ? "dbce-c360-segamaws-dev-f90e"
          : deploymentEnv === "test"
          ? "dbce-c360-segamaws-stg-f30f"
          : "dbce-c360-segamaws-prod-9669",
      instance: "cdp-profiles",
      schema: "cdp-profiles",
      ecoSystem: "CDP2.0",
    },
    spannerDetails: {
      project:
        deploymentEnv === "local"
          ? "dbce-c360-mdm-dev-9eba"
          : deploymentEnv === "test"
          ? "dbce-c360-mdm-stg-e2a7"
          : "dbce-c360-mdm-prod-2a9c",
      instance: "cdp-profiles",
      schema: "cdp-profiles",
      ecoSystem: "CDP2.0",
    },
  },
  destinationDetails: {
    crmActivation: {
      project:
        deploymentEnv === "local"
          ? "be dbce-c360-mdm-dev-9eba"
          : deploymentEnv === "test"
          ? "dbce-c360-mdm-stg-e2a7"
          : "dbce-c360-mdm-prod-2a9c",
      instance: "cdp-profiles",
      schema: "cdp-profiles",
      ecoSystem: "CDP2.0",
    },
    websitePersonalization: {
      project:
        deploymentEnv === "local"
          ? "dbce-c360-mdm-dev-9eba"
          : deploymentEnv === "test"
          ? "dbce-c360-mdm-stg-e2a7"
          : "dbce-c360-mdm-prod-2a9c",
      instance: "cdp-traits",
      schema: "cdp-traits",
      ecoSystem: "CDP2.0",
    },
  },
};

export default insightsModuleConfig;
