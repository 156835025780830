import { useState, useEffect, useCallback, useMemo } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createInsightRequest from "../../../api/create-insight-request";
import getInsight from "../../../api/get-insight";
import getInsights from "../../../api/get-insights";

import getRequestDetails from "../../../api/get-request-details";

import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import { AccordionModalDetails } from "../../../components/AccordionModal";
// import useAccordionStyles from "../../../components/AccordionModal/styles";
// import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";

import CustomAutoComplete from "../../../components/CustomAutoComplete";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import TextBox from "../../../components/TextBox";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import insightsModuleConfig from "../../../config/insightModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";

import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import { isEmptyString } from "../../../utilities/formValidation";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";
import NewInsight from "../Components/NewInsight";
import NewMpMapping from "../Components/NewMpMapping";

import InsightRequestOutputModal from "./InsightRequestOutputModal";
import useStyles from "./styles";

const CreateInsightContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const { requestStatus } = applicationConfig;

  const { sourceDetails, destinationDetails } = insightsModuleConfig;
  const { createEventConstants } = eventsModuleConfig;
  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const location = useLocation();
  const [output, setOutput] = useState({});
  const [showOutput, setShowOutput] = useState(false);
  const [openNewMpMapping, setOpenNewMpMapping] = useState(false);
  const [mpMappings, setMpMappings] = useState([]);
  const [rowIndex, setRowIndex] = useState(null);

  const initialValues = {
    marketingProgram: "",
    marketingProgramNumber: 1,
    description: "",
    schedule: "",
    status: "",
    computeFrequencyType: "",
    batchSchedule: "",
    processingType: "",
    source: {
      project: sourceDetails.bigQueryDetails.project,
      instance: sourceDetails.bigQueryDetails.instance,
      schema: "",
      ecoSystem: sourceDetails.bigQueryDetails.ecoSystem,
    },
    destination: {
      project: destinationDetails.crmActivation.project,
      instance: destinationDetails.crmActivation.instance,
      schema: "",
      ecoSystem: destinationDetails.crmActivation.ecoSystem,
    },
    isNew: true,
    isUpdated: false,
    selectedSourceValue: "SQL Expression for BigQuery",
    selectedDestinationValue: "CRM Activation",
  };

  const [mpMappingInfo, setMpMappingInfo] = useImmer({ ...initialValues });

  const {
    location: { state },
  } = history;

  let requestId = null;
  if (location.state) {
    requestId = location.state?.requestId;
  }
  const [currentStep, setCurrentStep] = useState(1);
  const [eventsSelected, setEventsSelected] = useState([
    {
      title: "",
      isSelected: false,
      isNewEvent: false,
      insightId: "",
    },
  ]);
  const [insightDetails, setInsightDetails] = useState([]);
  const { insightMappingsKeys } = traitsModuleConfig;
  const [storeDataLoading, setStoreDataLoading] = useState(false);

  useEffect(() => {
    const fetchInsightMappings = async () => {
      if (
        currentStep === 2 &&
        eventsSelected.some((event) => event.isSelected && !event.isNewEvent)
      ) {
        const selectedInsight = eventsSelected.find(
          (event) => event.isSelected
        );
        setStoreDataLoading(true);
        try {
          const response = await getInsight(selectedInsight.insightId); // Assuming insightId is available
          setInsightDetails(response?.insightDetail);
          setMpMappings(response.insightMappings || []); // Set mpMappings from the API response
        } catch (error) {
          handleError({ error, addNotification }); // Handle error appropriately
        } finally {
          setStoreDataLoading(false);
        }
      }
    };
    if (mpMappings?.length === 0) {
      fetchInsightMappings();
    }
  }, [currentStep, eventsSelected]);

  useEffect(() => {
    if (
      state?.insight !== undefined &&
      Object.entries(state.insight.insightDetail).length > 0
    ) {
      const makeSelectedObject = {
        insightId:
          state.insight.insightDetail.insightsId ||
          state.insight.insightDetail.insightId,
        title:
          state.insight.insightDetail.insightsName ||
          state.insight.insightDetail.insightName,
        isSelected: true,
        insightName:
          state.insight.insightDetail.insightsName ||
          state.insight.insightDetail.insightName,
        description: state.insight.insightDetail.description,
        // isNewEvent: true,
      };
      setCurrentStep(currentStep + 1);
      setEventsSelected([makeSelectedObject]);
    }
  }, [state]);

  useEffect(() => {
    if (location.pathname === applicationConfig.pathnames.insight_new) {
      // if (Object.keys(request).length === 0 && !requestId) {
      //   history.goBack();
      // }
    }
  }, []);

  const isRequestRevisionFlow =
    Object.keys(request).length !== 0 || Boolean(requestId);
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openInsightMappingDeleteModal, setOpenInsightMappingDeleteModal] =
    useState(false);

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.useEvents
  );

  const totalSteps = 3;

  const { t } = useTranslation();

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [formErrors, setFormErrors] = useState({});

  const [eventValue, setEventValue] = useState("");
  const [eventOptions, setEventOptions] = useState([]);
  const [initialEventOptions, setInitialEventOptions] = useState([]);
  const [eventsInfo, setEventsInfo] = useImmer({
    insightsName: "",
    description: "",
    inputTraits: [],
    outputTrait: "",
    customIndicator: "No",
    sqlExpression: "",
    isNew: true,
    isUpdated: false,
  });
  const [eventSelectedIndex, setEventSelectedIndex] = useState(-1);
  const [isNewEventDisabled, setIsNewEventDisabled] = useState(false);

  const [eventRequestResponse, setEventRequestResponse] = useState([]);

  // current event index
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const [eventsLoading, setEventsLoading] = useState(false);

  const diffList = useMemo(() => {
    return mpMappings.filter((item) => item.isNew === true);
  }, [mpMappings]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
    setEventsInfo({
      insightsName: "",
      description: "",
      inputTraits: [],
      outputTrait: "",
      customIndicator: "No",
      sqlExpression: "",
    });
    setEventValue("");
    setIsNewEventDisabled(false);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const validateForm = (step) => {
    const errors = {};
    if (step === 1) {
      // 1
      if (
        eventsSelected.filter((insight) => insight.title).length !==
        eventsSelected.length
      ) {
        errors.events = eventsSelected.map((insight) => {
          if (!insight.title) {
            return {
              error: t("create_event.errors.select_event"),
            };
          }
          return {
            error: "",
          };
        });
      }
    }
    if (step === 2) {
      if (isEmpty(diffList)) {
        addNotification(t("No changes Done."));
        errors.noChangesError = t("create_use_case.no_events_changes");
      }
    }
    setFormErrors(errors);
    return errors;
  };

  const isInfoStep = currentStep > totalSteps;

  const newEvent = (
    <div className="newEvent">
      <Typography variant="h6">
        {t("insight_container.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
      >
        {t("insight_container.new_insight")}
      </Button>
    </div>
  );

  const getEventsFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const filterFieldMap = {
        searchText: "searchText",
      };

      const rsp1 = await getInsights(
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap, // new URLSearchParams(filter).toString(),
          1,
          6
        )
      );
      // parseSearchFilter([filters], filterFieldMap, page, perPage)
      setEventOptions([...rsp1.items, newEvent]);
      if (searchText.length === 0) {
        if (initialEventOptions.length > 0) {
          const newInitialEventOptions = [...initialEventOptions];
          newInitialEventOptions.splice(
            newInitialEventOptions.length - 1,
            0,
            ...rsp1.items
          );
          setInitialEventOptions(newInitialEventOptions);
        } else {
          setInitialEventOptions([...rsp1.items, newEvent]);
        }
      }
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setEventOptions([newEvent]);
        },
        addNotification,
      });
    } finally {
      setEventsLoading(false);
    }
  }, []);

  const handleSubmit = (eventInfo) => {
    const currentEvents = [...eventsSelected];
    currentEvents[currentEventIndex] = {
      ...eventInfo,
      title: eventInfo.eventName,
      insightId: "",
      isSelected: true,
      isNewEvent: true,
    };

    setEventsSelected(currentEvents);
    handleClose();
  };

  // Debounce & Memoize Api Calls
  const debouncedEventsFromApi = debounce(
    getEventsFromApi,
    applicationConfig.waitTime
  );

  const memoizedEventsFromApi = useCallback(async (val) => {
    debouncedEventsFromApi(val);
  }, []);

  useEffect(() => {
    if (eventValue.length > 0) {
      memoizedEventsFromApi(eventValue);
    }
  }, [eventValue]);

  const setInsightsData = (data) => {
    const { insight, marketingPrograms } = data.requestDetails;
    setEventsSelected([
      {
        ...insight,
        title: insight.insightName,
        isSelected: true,
      },
    ]);

    const modifyMarketingPrograms = { ...marketingPrograms };
    const result = modifyMarketingPrograms[0];
    const finalMarketingProgram = {
      ...result,
      computeFrequency: `${result.computeFrequency} - ${result.schedule}`,
      marketingProgram: `${result.marketingProgramNumber} - ${result.description}`,
      isSelected: true,
    };

    // setEditMarketingProgram([finalMarketingProgram]);
    setMpMappings([finalMarketingProgram]);
    // setMpMappingInfo(marketingPrograms);
    setCurrentStep(2);
  };

  useEffect(async () => {
    if (isRequestRevisionFlow && !requestId) {
      setInsightsData(request);
    } else if (requestId) {
      const details = await getRequestDetails(requestId);
      setInsightsData(details);
    }
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(2);
      await getEventsFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  const modifyInputTraits = (mInputTraits) => {
    const modInputTraits = [];
    if (mInputTraits && mInputTraits?.length > 0) {
      mInputTraits.forEach((item) => {
        modInputTraits.push({
          traitId: item.split("-")[0],
          traitName: item.split("-")[1],
        });
      });
    }
    return modInputTraits;
  };

  const customIndecatorModify = (checkValue) => {
    if (checkValue === "Yes") {
      return true;
    }
    return false;
  };

  const modifyOutputTrait = (mOutputTraits) => {
    let modOutputTraits = {};
    if (mOutputTraits && mOutputTraits !== undefined) {
      modOutputTraits = {
        traitId: mOutputTraits.split("-")[0],
        traitName: mOutputTraits.split("-")[1],
      };
    }
    return modOutputTraits;
  };

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.insight_name"),
        value: eventsSelected.map((insight) => {
          if (insight.isNewEvent) {
            return (
              <div
                key={`${insight.title}`}
                className={clsx(classes.flexContainer, classes.marginTop20)}
              >
                <div
                  className={classes.newBtnContainer}
                  onClick={() => {
                    setIsNewEventDisabled(true);
                    setEventsInfo(insight);
                    setOpenModal(true);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  <span className="eventName">{insight.title}</span>
                  <Button variant="contained">{t("common.new")}</Button>
                </div>
              </div>
            );
          }
          return <div>{insight.title}</div>;
        }),
      },
      {
        label: t("common.labels.insight_description"),
        value: eventsSelected.map((insight) => {
          return <div>{insight.description}</div>;
        }),
      },
    ];
    return info;
  };

  const handleInsightOutputModal = async () => {
    try {
      setShowOutput(true);
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const createRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "insightName",
      headerName: t("common.labels.insight_name"),
      flex: 1,
      renderCell: (params) => {
        const { value, row } = params;
        return row.isNewEvent ? (
          <div role="button" aria-hidden="true" className={classes.eventName}>
            <span>{value}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        ) : (
          <span>{value}</span>
        );
      },
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  handleInsightOutputModal(params.row.requestId);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const creteInputTraits = (inputTraitsVal) => {
    const inputTraits = [];
    inputTraitsVal?.forEach((item) => {
      inputTraits.push({ traitId: item.traitId, traitName: item.traitName });
    });
    return inputTraits;
  };

  const modifyInsightName = (insightObject) => {
    if (insightObject.insightName) {
      return insightObject.insightName;
    }
    return insightObject.insightsName;
  };

  const onCloseInsightMappingDeleteModal = () => {
    setOpenInsightMappingDeleteModal(false);
  };

  const renderStep = (step) => {
    if (step === 1) {
      // 1
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      const eventDropdownValues =
        eventValue.length > 0 ? eventOptions : initialEventOptions;
      return (
        <div className={classes.center} data-testid="insights-container">
          {eventsSelected?.map((insight, eventIndex) => {
            // eslint-disable-next-line no-param-reassign
            insight.title = insight?.title
              ? insight?.title
              : insight?.insightsName;
            const eventName = insight.title;
            let errorText = "";
            if (formErrors.events && formErrors.events[eventIndex]) {
              errorText = formErrors.events[eventIndex].error;
            }
            return !insight.isSelected ? (
              <div
                className={clsx(classes.flexContainer, classes.autoCompleteBox)}
                key={`${eventName} - ${eventIndex + 1}`}
              >
                <CustomAutoComplete
                  isMultiple={false}
                  classes={{
                    listbox: classes.listbox,
                  }}
                  options={(() => {
                    const options = eventDropdownValues.map((option) => {
                      if (option.type !== "div") {
                        return {
                          title: `${option.insightsId} - ${option.insightsName}`,
                          ...option,
                        };
                      }
                      return option;
                    });
                    if (options.length > 4) {
                      return [...options.slice(0, 3), ...options.slice(-1)];
                    }
                    return options;
                  })()}
                  id={`insight-${eventIndex}`}
                  placeholder={
                    loading
                      ? t("common.loading")
                      : t(
                          "insight_container.insight_select_or_insight_placeholder"
                        )
                  }
                  loading={eventsLoading}
                  value={
                    insight.title && [
                      {
                        title: insight.title,
                      },
                    ]
                  }
                  closeIcon={<></>}
                  isExternalInputValue
                  inputValue={eventValue}
                  setValue={(val) => {
                    if (val && val.type !== "div") {
                      if (val) {
                        setEventsSelected([
                          {
                            ...val,
                            title: val.title,
                            insightId: val.insightsId,
                            isSelected: true,
                          },
                        ]);
                      }
                    }
                  }}
                  onOpen={() => {
                    setCurrentEventIndex(eventIndex);
                  }}
                  onInputChange={(val) => {
                    setEventValue(val);
                    setEventOptions([]);
                    setEventsLoading(true);
                  }}
                  error={Boolean(errorText)}
                  errorText={errorText}
                />
                {eventIndex !== 0 && (
                  <div
                    className={classes.autoCompleteDeleteContainer}
                    onClick={() => {
                      const newInsights = [...eventsSelected];
                      newInsights.splice(eventIndex, 1);
                      if (newInsights.length > 0) {
                        setEventsSelected(newInsights);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </div>
                )}
              </div>
            ) : (
              <div
                key={`${eventName}-${eventIndex + 1}`}
                className={classes.fullWidth}
              >
                <TextBox
                  isNew={eventsSelected[eventIndex]}
                  name={eventName}
                  onClick={() => {
                    // EDIT NEW INSIGHT
                    if (eventsSelected[eventIndex]) {
                      setEventsInfo({
                        ...eventsSelected[eventIndex],
                      });
                      setCurrentEventIndex(eventIndex);
                      setOpenModal(true);
                    } else {
                      // DELETE INSIGHT
                      setOpenDeleteModal(true);
                      setEventSelectedIndex(eventIndex);
                    }
                  }}
                  onDelete={() => {
                    // DELETE INSIGHT
                    setOpenDeleteModal(true);
                    setEventSelectedIndex(eventIndex);
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }
    if (step === 2) {
      if (storeDataLoading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <>
          <div className={classes.marketingProgramContainer}>
            <AccordionModalDetails
              keys={insightMappingsKeys}
              noScroll
              rows={mpMappings?.map((item) => {
                const updatedItem = item;
                if (item.source) {
                  updatedItem.sourceDetailsText = `
                    <p>
                      ${
                        item?.source?.ecoSystem
                          ? `Ecosystem: ${item.source.ecoSystem}<br>`
                          : ""
                      }
                      ${
                        item?.source?.project
                          ? `Computation Project: ${item.source.project}<br>`
                          : ""
                      }
                      ${
                        item?.source?.instance
                          ? `Computation Instance: ${item.source.instance}<br>`
                          : ""
                      }
                      ${
                        item?.source?.computationSchema
                          ? `Computation Schema: ${item.source.computationSchema}`
                          : ""
                      }
                    </p>
                  `;
                }
                if (item.destination) {
                  updatedItem.destinationDetailsText = `
                    <p>
                      ${
                        item?.destination?.ecoSystem
                          ? `Ecosystem: ${item.destination.ecoSystem}<br>`
                          : ""
                      }
                      ${
                        item?.destination?.project
                          ? `Computation Project: ${item.destination.project}<br>`
                          : ""
                      }
                      ${
                        item?.destination?.instance
                          ? `Computation Instance: ${item.destination.instance}<br>`
                          : ""
                      }
                      ${
                        item?.destination?.computationSchema
                          ? `Computation Schema: ${item.destination.computationSchema}`
                          : ""
                      }
                    </p>
                  `;
                }
                return updatedItem;
              })}
              showEdit={(item) => item.new}
              showDelete={(item) => item.new}
              onEdit={(row, index) => {
                setMpMappingInfo({
                  ...row,
                  marketingProgram: {
                    marketingProgramNumber: row.marketingProgram
                      .split("-")[0]
                      .trim(),
                    description: row.marketingProgram.split("-")[1].trim(),
                  },
                });
                setRowIndex(index);
                setOpenNewMpMapping(true);
              }}
              onDelete={(row, index) => {
                setRowIndex(index);
                setOpenInsightMappingDeleteModal(true);
              }}
              rowKeys={{
                title: "marketingProgram",
                subTitle: "computeFrequency",
              }}
            />
            <div className={globalStyles.addTraitBtn}>
              <AddCircleOutlineIcon />
              <Button onClick={() => setOpenNewMpMapping(true)}>
                {t("insight_container.new_mp_mapping")}
              </Button>
            </div>
            {openNewMpMapping && (
              <NewMpMapping
                openModal={openNewMpMapping}
                handleClose={() => setOpenNewMpMapping(false)}
                title={t("insight_container.new_mp_mapping")}
                handleSubmit={() => {
                  if (!isEmptyString(rowIndex)) {
                    setMpMappings((prevMappings) => {
                      const updatedMappings = [...prevMappings];
                      updatedMappings[rowIndex] = {
                        ...mpMappingInfo,
                        computeFrequency: `${mpMappingInfo.computeFrequencyType} - ${mpMappingInfo.batchSchedule}`,
                        marketingProgram: `${mpMappingInfo.marketingProgram.marketingProgramNumber} - ${mpMappingInfo.marketingProgram.description}`,
                        new: true,
                      };
                      return updatedMappings;
                    });
                  } else {
                    setMpMappings([
                      ...mpMappings,
                      {
                        ...mpMappingInfo,
                        computeFrequency: `${mpMappingInfo.computeFrequencyType} - ${mpMappingInfo.batchSchedule}`,
                        marketingProgram: `${mpMappingInfo.marketingProgram.marketingProgramNumber} - ${mpMappingInfo.marketingProgram.description}`,
                        new: true,
                      },
                    ]);
                  }
                  setMpMappingInfo(initialValues);
                  setOpenNewMpMapping(false);
                  setRowIndex(null);
                }}
                mpMappingInfo={mpMappingInfo}
                setMpMappingInfo={setMpMappingInfo}
                selectedInsight={eventsSelected}
              />
            )}
          </div>
        </>
      );
    }
    if (step === 3) {
      // 3
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            style={{ alignItems: "flex-start" }}
            key={`${info.label}${info.index}`}
          >
            <Typography className="label" variant="h6">
              {info.label} :
            </Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });

      return (
        <>
          {detailsDOM}
          {diffList.length > 0 ? (
            <AccordionModalDetails
              keys={insightMappingsKeys}
              noScroll
              rows={diffList?.map((item) => {
                const updatedItem = item;
                if (item.source) {
                  updatedItem.sourceDetailsText = `
                    <p>
                      ${
                        item?.source?.ecoSystem
                          ? `Ecosystem: ${item.source.ecoSystem}<br>`
                          : ""
                      }
                      ${
                        item?.source?.project
                          ? `Computation Project: ${item.source.project}<br>`
                          : ""
                      }
                      ${
                        item?.source?.instance
                          ? `Computation Instance: ${item.source.instance}<br>`
                          : ""
                      }
                      ${
                        item?.source?.instance
                          ? `Computation Schema: ${item.source.instance}`
                          : ""
                      }
                    </p>
                  `;
                }
                if (item.destination) {
                  updatedItem.destinationDetailsText = `
                    <p>
                      ${
                        item?.destination?.ecoSystem
                          ? `Ecosystem: ${item.destination.ecoSystem}<br>`
                          : ""
                      }
                      ${
                        item?.destination?.project
                          ? `Computation Project: ${item.destination.project}<br>`
                          : ""
                      }
                      ${
                        item?.destination?.instance
                          ? `Computation Instance: ${item.destination.instance}<br>`
                          : ""
                      }
                      ${
                        item?.destination?.instance
                          ? `Computation Schema: ${item.destination.instance}`
                          : ""
                      }
                    </p>
                  `;
                }
                return updatedItem;
              })}
              rowKeys={{
                title: "marketingProgram",
                subTitle: "computeFrequency",
              }}
            />
          ) : (
            <Typography>No mappings available.</Typography> // Fallback message
          )}
        </>
      );
    }
    return (
      <div
        style={{
          height: 70 * eventRequestResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table
          columns={createRequestTableColumns}
          rows={eventRequestResponse}
        />
      </div>
    );
  };
  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/traits" goToText="GO TO TRAITS" />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        steps={[
          t("insight_container.headings.step_1"),
          t("insight_container.headings.step_2"),
          t("insight_container.headings.step_3"),
        ]}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        header={
          <>
            <Typography variant="h4" gutterBottom>
              {isRequestRevisionFlow
                ? createEventConstants.requestRevisionHeadings[currentStep]
                : createEventConstants.eventCreationHeadings[currentStep]}
            </Typography>
            {currentStep === 2 && (
              <Typography variant="h6" gutterBottom>
                {t("create_event.mapping_events")}:{" "}
                {eventsSelected.map((event, index) => {
                  if (event.isNewEvent) {
                    return (
                      <div
                        className={classes.newBtnContainer}
                        key={event.title}
                      >
                        <span>{event.title}</span>
                        <Button variant="contained">{t("common.new")}</Button>
                        {index !== eventsSelected.length - 1 && ","}
                      </div>
                    );
                  }

                  return index === eventsSelected.length - 1
                    ? event.title
                    : `${event.title}, `;
                })}
              </Typography>
            )}
          </>
        }
        headerText={t("insight_container.insight_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{
                        root: globalStyles.btn,
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                      root: globalStyles.btn,
                    }}
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && ( // totalSteps
              <Button
                disabled={loading}
                className="nextStep"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    // totalSteps
                    try {
                      increaseRequestsCount();
                      const outputTrait = {
                        traitId: eventsSelected[0]?.outputTrait?.traitId,
                        traitName: eventsSelected[0]?.outputTrait?.traitName,
                      };
                      const payload = {
                        insight: {
                          insightName:
                            modifyInsightName(eventsSelected[0]) ||
                            insightDetails.insightsName,
                          description: eventsSelected[0]?.description
                            ? eventsSelected[0]?.description
                            : insightDetails.description,
                          inputTraits:
                            creteInputTraits(eventsSelected[0]?.inputTraits)
                              ?.length > 0
                              ? creteInputTraits(eventsSelected[0]?.inputTraits)
                              : modifyInputTraits(insightDetails.inputTraits),
                          outputTrait:
                            outputTrait.traitId !== undefined
                              ? outputTrait
                              : modifyOutputTrait(insightDetails.outputTrait),
                          isSQLTrait: eventsSelected[0]?.customIndicator
                            ? customIndecatorModify(
                                eventsSelected[0]?.customIndicator
                              )
                            : customIndecatorModify(
                                insightDetails.customIndicator
                              ),
                          sqlExpression: eventsSelected[0]?.sqlExpression
                            ? eventsSelected[0]?.sqlExpression
                            : insightDetails.sqlExpression,
                          isNew: true,
                          isUpdated: false,
                        },
                        marketingPrograms: diffList.map(
                          (marketingProgramInfo) => ({
                            isUpdated: marketingProgramInfo.isUpdated,
                            isNew: marketingProgramInfo.isNew,
                            status: marketingProgramInfo.status,
                            marketingProgramNumber:
                              marketingProgramInfo.marketingProgram
                                .split("-")[0]
                                .trim(),
                            description: marketingProgramInfo.marketingProgram
                              .split("-")[1]
                              .trim(),
                            computeFrequency:
                              marketingProgramInfo.computeFrequencyType ||
                              marketingProgramInfo.computeFrequency.split(
                                "-"
                              )[0],
                            schedule:
                              marketingProgramInfo.batchSchedule ||
                              marketingProgramInfo.schedule,
                            processingType: marketingProgramInfo.processingType,
                            source: {
                              project: marketingProgramInfo.source.project,
                              instance: marketingProgramInfo.source.instance,
                              schema: `${marketingProgramInfo.source.instance} create`,
                              ecosystem: "CDP2.0",
                            },
                            destination: {
                              project: marketingProgramInfo.destination.project,
                              instance:
                                marketingProgramInfo.destination.instance,
                              schema: `${marketingProgramInfo.destination.instance} create`,
                              ecosystem: "CDP2.0",
                            },
                          })
                        ), // diffList,
                      };
                      if (
                        eventsSelected[0].insightId !== "" &&
                        eventsSelected[0].insightId !== undefined
                      ) {
                        payload.insight.insightId = eventsSelected[0].insightId
                          ? eventsSelected[0].insightId
                          : "";
                        payload.insight.isNew = false;
                        payload.insight.isUpdated = true;
                      }

                      if (!isRequestRevisionFlow) {
                        const data = await createInsightRequest(payload);
                        const { items } = data;
                        setEventRequestResponse(
                          items.map((item, index) => ({
                            id: index,
                            requestId: item.requestId,
                            insightName: item.insight.insightName,
                            status:
                              item.status.charAt(0).toUpperCase() +
                              item.status.slice(1),
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                        setOutput({
                          ...items?.[0]?.output,
                          status: items[0].status,
                          message: items[0]?.message,
                          description: items[0]?.insight?.description,
                        });
                      } else {
                        const data = await updateRequest(
                          request.requestId || requestId,
                          payload,
                          applicationConfig.modules.traits
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    const errors = validateForm(currentStep);
                    setFormErrors(errors);
                    if (Object.keys(errors).length > 0) {
                      return null;
                    }
                    setCurrentStep(currentStep + 1);
                  }
                  return null;
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}

                {createEventConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/insights"
                >
                  {t("common.labels.back_to_insight")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${eventRequestResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.eventContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {openModal && (
        <NewInsight
          openModal={openModal}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          eventsInfo={eventsInfo}
          setEventsInfo={setEventsInfo}
          isDisabled={isNewEventDisabled}
          eventsSelected={eventsSelected}
          isRequestRevisionFlow={isRequestRevisionFlow}
          title={t("insight_container.create_new_insight")}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("insight_container.insight_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          const newInsights = [...eventsSelected];
          newInsights.splice(eventSelectedIndex, 1);
          if (newInsights.length > 0) {
            setEventsSelected(newInsights);
          } else {
            setEventsSelected([
              {
                title: "",
                isSelected: false,
                isNewEvent: false,
                insightId: "",
              },
            ]);
          }
          onCloseDeleteModal();
        }}
        type="error"
      />
      <ConfirmationModal
        open={openInsightMappingDeleteModal}
        onClose={onCloseInsightMappingDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("insight_container.insight_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          setMpMappings((prevMappings) => {
            const updatedMappings = [...prevMappings];
            updatedMappings.splice(rowIndex, 1);
            return updatedMappings;
          });
          setRowIndex(null);
          onCloseInsightMappingDeleteModal();
        }}
        type="error"
      />
      {currentStep <= 3 && (
        <Prompt
          message={() => {
            if (isRequestRevisionFlow) {
              return true;
            }
            return t("prompt.progress_lost");
          }}
        />
      )}
      {showOutput && (
        <InsightRequestOutputModal
          isOpen={showOutput}
          isLoading={loading}
          requestId={eventRequestResponse[0].requestId}
          data={output}
          setShowOutput={setShowOutput}
        />
      )}
    </>
  );
};

export default CreateInsightContainer;
