import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import AccordionRowDetails from "../../../../../components/AccordionRowDetails";
import CustomModal from "../../../../../components/CustomModal";
import applicationConfig from "../../../../../config/applicationConfig";
import traitsModuleConfig from "../../../../../config/traitsModuleConfig";

import useStyles from "./styles";

const InsightDetailsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
  request,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const { insightDetail, insightMappings } = data;

  const { insightMappingsKeys } = traitsModuleConfig;

  const insightsName = () => {
    return insightDetail?.insightsName ? "insightsName" : "insightName";
  };

  const renderUi = () => {
    if (tab === 0) {
      return (
        <AccordionRowDetails
          keys={[
            {
              name: t("common.labels.insight_id"),
              id: "insightsId",
            },
            {
              name: t("common.labels.insight_name"),
              id: insightsName(),
            },
            {
              name: t("common.labels.insight_description"),
              id: "description",
            },
            {
              name: t("common.labels.input_traits"),
              id: "inputTraits",
            },
            {
              name: t("common.labels.output_trait"),
              id: "outputTrait",
            },
            {
              name: "SQL Custom Indicator",
              id: "isSQLTrait",
            },
            {
              name: "SQL Expression",
              id: "sqlExpression",
              preformat: true,
              formatName: "SQL",
            },
          ]}
          row={insightDetail}
        />
      );
    }
    return (
      <AccordionModalDetails
        keys={insightMappingsKeys}
        rows={insightMappings?.map((item) => {
          const updatedItem = item;
          if (item.sourceDetails?.ecoSystem) {
            updatedItem.sourceDetailsText = `<p>Ecosystem: ${item?.sourceDetails?.ecoSystem}<br>Computation Project: ${item?.sourceDetails.computationProject}<br>Computation Instance: ${item?.sourceDetails.computationInstance}<br>Computation Schema: ${item?.sourceDetails.computationSchema}</p>`;
          }
          if (item.destinationDetails?.ecoSystem) {
            updatedItem.destinationDetailsText = `<p>Ecosystem: ${item?.destinationDetails?.ecoSystem}<br>Computation Project: ${item?.destinationDetails.computationProject}<br>Computation Instance: ${item?.destinationDetails.computationInstance}<br>Computation Schema: ${item?.destinationDetails.computationSchema}</p>`;
          }
          return updatedItem;
        })}
        rowKeys={{
          title: "marketingProgram",
          subTitle: "computeFrequency",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      data={{
        insightDetail,
        insightMappings,
      }}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
            }}
          >
            <Tab label="Insight Details" />
            <Tab label="Marketing Programs" />
          </Tabs>
          {renderUi()}
        </div>
      </div>
      {!request && (
        <Button
          style={{ marginTop: "10px" }}
          variant="contained"
          disabled={loading}
          component={Link}
          to={{
            pathname: applicationConfig.pathnames.insight_new,
            state: {
              insight: data,
            },
          }}
          onClick={() => {}}
          className={globalClasses.btn}
        >
          {t("insight_container.manage_insights")}
        </Button>
      )}
    </CustomModal>
  );
};

InsightDetailsModal.defaultProps = {
  title: null,
};

InsightDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    insightDetail: PropTypes.shape({
      insightsId: PropTypes.number.isRequired,
      insightsName: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      inputTraits: PropTypes.arrayOf(PropTypes.string),
      outputTrait: PropTypes.string,
      customIndicator: PropTypes.string,
      sqlExpression: PropTypes.string,
    }).isRequired,
    insightMappings: PropTypes.shape([]).isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.string.isRequired,
};

export default InsightDetailsModal;
