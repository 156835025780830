import { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../components/RequestOutputModal";
import StatusBadge from "../../../../components/StatusBadge";
import Table from "../../../../components/Table";

const EventRequestOutputModal = ({
  setShowOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "insightName",
      headerName: t("common.labels.insight_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },

    {
      field: "description",
      headerName: t("common.labels.insight_description"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },

    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const rows = useMemo(() => {
    return (
      data?.items?.map((item, index) => {
        const row = {
          id: `${item.insightName}-${index}`,
          insightName: item.insightName,
          description: data.description,
          status: data.status,
          message: data.message,
        };

        return row;
      }) || []
    );
  }, [data]);

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.insight_output_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

EventRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

EventRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.objectOf({
    items: PropTypes.shape({
      dataType: PropTypes.string,
      description: PropTypes.string,
      insightName: PropTypes.string,
    }),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EventRequestOutputModal;
