import { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

const DisconnectSourcesRequestOutputModal = ({
  setShowOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "sourceId",
      headerName: t("common.labels.source_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "marketingProgramNumber",
      headerName: t("common.labels.marketing_program_number"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const rows = useMemo(() => {
    return data?.items?.map((item, index) => {
      return {
        id: `${item.sourceId}-${index}`,
        sourceId: item.sourceId,
        marketingProgramNumber: item.marketingProgramNumber
          ? `${item.marketingProgramNumber}`
          : "",
        status: data.status,
        message: data.message,
      };
    });
  }, [data]);

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("disconnect_sources.disconnect_output_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

DisconnectSourcesRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

DisconnectSourcesRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.objectOf({
    items: PropTypes.shape({
      sourceId: PropTypes.string,
      marketingProgramNumber: PropTypes.string,
      status: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default DisconnectSourcesRequestOutputModal;
