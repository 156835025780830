import api from "../utilities/api";

const getInsightFrequency = async (insightId) => {
  const apiUrl = `/insights/invalid/frequency`;
  const {
    data: { data },
  } = await api.get(`${apiUrl}/${insightId}`);

  return data;
};

export default getInsightFrequency;
