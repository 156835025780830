import { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";

import {
  Button,
  Typography,
  CircularProgress,
  Box,
  FormHelperText,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory } from "react-router-dom";

import disconnectDataSourceMappings from "../../../api/disconnect-data-source-mappings";
import getSourceDetails from "../../../api/get-source-details";
import getSourcesApi from "../../../api/get-sources";
import updateRequest from "../../../api/update-request";

import AccessDenied from "../../../components/AccessDenied";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsResponsesModuleConfig from "../../../config/traitsResponsesModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import DisconnectSourcesRequestOutputModal from "./components/DisconnectSourcesRequestOutputModal";
import MarketingPrograms from "./components/MarketingPrograms";

import useStyles from "./styles";

const DisconnectSourcesContainer = () => {
  const classes = useStyles();
  const { user } = useUserProfile();
  const { createTraitsResponsesConstants, requestStatus } =
    traitsResponsesModuleConfig;

  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const {
    location: { state },
  } = history;

  const count = useRef(0);
  count.current += 1;

  const isRequestRevisionFlow = useMemo(
    () => Object.keys(request ?? {}).length !== 0 && request.requestId,
    [request]
  );
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);
  const isUserAuthorized = useMemo(
    () => checkUserAuthorization(user.access, pageAccessConfig.createSources),
    [user.access]
  );
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(
    (state?.event?.eventName && state?.event?.propertyName) ||
      state?.trait?.traitName
      ? 2
      : 1
  );
  const [initialStep] = useState(1);
  const { t } = useTranslation();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [dataSourceResponse, setDatasourceResponse] = useState([]);
  const [sourceSelected, setSourceSelected] = useState({});
  const globalTraitResponses = useRef([]);
  const [diffList, setDiffList] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  const [dataSourceValue, setDataSourceValue] = useState("");
  const [sourcesOptions, setSourcesOptions] = useState([]);
  const [initialSourceOptions, setInitialSourceOptions] = useState([]);

  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [marketingPrograms, setMarketingPrograms] = useState();
  const [output, setOutput] = useState({});
  const [showOutput, setShowOutput] = useState(false);

  useEffect(() => {
    if (
      state?.dataSource !== undefined &&
      Object.entries(state.dataSource).length > 0
    ) {
      const makeSelectedObject = {
        title: `${state.dataSource.sourceId} - ${state.dataSource.sourceName}`,
        sourceName: state.dataSource.sourceName,
        sourceId: state.dataSource.sourceId,
      };
      setSourceSelected(makeSelectedObject);
      setCurrentStep(currentStep + 1);

      if (
        state.dataSource?.marketingPrograms &&
        Array.isArray(state.dataSource?.marketingPrograms)
      ) {
        setMarketingPrograms(state.dataSource?.marketingPrograms);
      } else {
        setMarketingPrograms([]);
      }
    }
  }, [state]);

  const isInfoStep = currentStep > totalSteps;

  useEffect(() => {
    if (isRequestRevisionFlow) {
      const makeSelectedObject = {
        title: `${request.requestDetails?.dataSources[0]?.sourceId} - ${request.requestDetails?.dataSources[0]?.sourceName}`,
        sourceName: request.requestDetails?.dataSources[0]?.sourceName,
        sourceId: request.requestDetails?.dataSources[0]?.sourceId,
      };
      setSourceSelected(makeSelectedObject);
      setCurrentStep(2);
    }
  }, [request, isRequestRevisionFlow]);

  useEffect(async () => {
    try {
      increaseRequestsCount();
      const data = await getSourceDetails(sourceSelected?.sourceId);
      setMarketingPrograms(data?.marketingPrograms);
    } catch (error) {
      handleError({
        error,
        handle404: true,
        addNotification,
      });
    } finally {
      decreaseRequestsCount();
    }
  }, [sourceSelected]);

  const getSourcesFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
      };
    }
    const filterFieldMap = {
      searchText: "searchText",
    };
    try {
      setSourcesLoading(true);
      const rsp1 = await getSourcesApi(
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap,
          1,
          3
        )
      );
      if (searchText.length === 0) {
        setInitialSourceOptions([...rsp1.items]);
      }
      setSourcesOptions([...rsp1.items]);
    } catch (error) {
      handleError({
        error,
        handle404: true,
        addNotification,
      });
    } finally {
      setSourcesLoading(false);
    }
  }, []);

  const debouncedSourcesFromApi = debounce(
    getSourcesFromApi,
    applicationConfig.waitTime
  );

  const memoizedSourcesFromApi = useCallback(async (searchText) => {
    debouncedSourcesFromApi(searchText);
  }, []);

  useEffect(() => {
    if (dataSourceValue.length > 0) {
      memoizedSourcesFromApi(dataSourceValue);
    }
  }, [dataSourceValue]);
  const sourceDropdownValues =
    dataSourceValue.length > 0 ? sourcesOptions : initialSourceOptions;

  useEffect(() => {
    if (currentStep === 1) {
      globalTraitResponses.current = [];
    }
  }, [currentStep]);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(1);
      await getSourcesFromApi("");
      decreaseRequestsCount(1);
    }
  }, [user]);

  const handleEventOutputModal = async () => {
    try {
      setShowOutput(true);
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const DisconnectSourcesRequestOutputTable = useMemo(
    () => [
      {
        field: "requestId",
        headerName: t("common.labels.request_id"),
        flex: 1,
        sortable: false,
        disableToggle: true,
      },

      {
        field: "sourceName",
        headerName: t("disconnect_sources.source_name"),
        flex: 1,
        sortable: false,
      },
      {
        field: "status",
        headerName: t("status.status"),
        flex: 1,
        renderCell: (params) => {
          return (
            <div className={classes.statusWrapper}>
              <StatusBadge
                status={params.value}
                showTooltip
                onTooltipClick={() => {
                  if (
                    params.value.toLowerCase() ===
                    requestStatus.APPROVED.toLowerCase()
                  ) {
                    handleEventOutputModal(params.row.requestId);
                  }
                }}
              />
            </div>
          );
        },
        sortable: false,
      },
    ],
    [classes.statusWrapper, requestStatus.APPROVED, t, dataSourceResponse]
  );

  let headerText = "";
  if (isRequestRevisionFlow) {
    headerText = t("disconnect_sources.data_source_revision");
  } else {
    headerText = t("disconnect_sources.disconnect_sources");
  }

  const details = useMemo(
    () => [
      {
        label: t("common.labels.source"),
        value: sourceSelected?.title,
      },
    ],
    [t, sourceSelected?.title]
  );

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/sources"
      goToText={t("access_denied.go_to_sources")}
    />
  ) : (
    <>
      <InputFlow
        steps={[
          t("disconnect_sources.headings.step_1"),
          t("disconnect_sources.headings.step_2"),
          t("disconnect_sources.headings.step_3"),
        ]}
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading}
        headerText={headerText}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ||
                (currentStep === 2 && initialStep !== 1) ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                variant="outlined"
                onClick={async () => {
                  setErrors({});
                  if (currentStep === 1) {
                    const errs = {};
                    if (isEmpty(sourceSelected)) {
                      errs.dataSourceError = t("errors.field_required");
                    }
                    setErrors(errs);
                    const errorValues = Object.values(errs);
                    if (errorValues.length > 0) {
                      return;
                    }
                  }
                  if (currentStep === 2) {
                    if (isEmpty(diffList)) {
                      addNotification("No Changes Done.");
                      return;
                    }
                  }
                  setErrors({});
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const modifiedMarketingProgram = [];
                      diffList.forEach((item) => {
                        modifiedMarketingProgram.push({
                          marketingProgramNumber: item.marketingProgramNumber,
                          description: item.marketingProgramDescription,
                        });
                      });
                      const payload = {
                        dataSources: [
                          {
                            sourceId: sourceSelected?.sourceId,
                            sourceName: sourceSelected?.sourceName,
                          },
                        ],
                        marketingPrograms: modifiedMarketingProgram,
                      };
                      let data;
                      if (isRequestRevisionFlow) {
                        data = await updateRequest(
                          request.requestId,
                          payload,
                          applicationConfig.modules.data_source
                        );
                        setRequest(data);
                        setIsRequestRevisionUpdated(true);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      } else {
                        data = await disconnectDataSourceMappings(payload);
                        const { items } = data;
                        setDatasourceResponse(
                          data.items.map((item, index) => ({
                            ...item,
                            id: index,
                            requestId: `CRS${item.requestId}`,
                            status: item.status.toUpperCase(),
                            sourceName: item.dataSources[0].sourceName,
                            output: item.output,
                            sourceId: item.dataSources[0].sourceId,
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                        setOutput({
                          ...items?.[0]?.output,
                          status: items[0].status,
                          message: items[0]?.message,
                        });
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.7,
                    }}
                  >
                    <CircularProgress color="palette.static.white" size={20} />
                  </Box>
                )}
                {createTraitsResponsesConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={classes.lastStepFooter}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/sources"
                >
                  {t("common.labels.back_to_sources")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${
                    dataSourceResponse[0].requestId.startsWith("CRS")
                      ? dataSourceResponse[0].requestId.slice(3)
                      : dataSourceResponse[0].requestId
                  }`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div className={clsx(isInfoStep && classes.paddingZero)}>
          {currentStep === 1 && loading && isEmpty(sourceSelected) && (
            <>
              <Skeleton height={65} />
              <Skeleton height={65} />
            </>
          )}
          {currentStep === 1 && (!loading || !isEmpty(sourceSelected)) && (
            <div className={classes.marketingProgramContainer}>
              <div
                className={clsx(classes.flexContainer, classes.justifyContent)}
                key="ecosystem-container"
              >
                <>
                  <Typography>{t("common.labels.source")}</Typography>
                  <div>
                    <CustomAutoComplete
                      disableCloseOnSelect={false}
                      className="source-disconnect"
                      isMultiple={false}
                      options={sourceDropdownValues.map((option) => ({
                        ...option,
                        title: `${option.sourceId} - ${option.sourceName}`,
                      }))}
                      filterOptions={(options) => options}
                      id="disconnect"
                      placeholder={t("autocomplete.data_source_placeholder")}
                      inputValue={dataSourceValue || sourceSelected.title}
                      isExternalInputValue
                      loading={sourcesLoading}
                      value={sourceSelected}
                      setValue={(val) => {
                        setSourceSelected(val || {});
                        setDataSourceValue("");
                      }}
                      onInputChange={(val) => {
                        setSourcesLoading(true);
                        setSourcesOptions([]);
                        setDataSourceValue(val);
                      }}
                    />
                    {errors.dataSourceError && (
                      <FormHelperText error>
                        {errors.dataSourceError}
                      </FormHelperText>
                    )}
                  </div>
                </>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <DetailsDOM details={details} />
              <div className={classes.confirmationContainer}>
                <Typography variant="h4">
                  {t("disconnect_sources.mapped_mpns")}
                </Typography>
                <MarketingPrograms
                  marketingPrograms={marketingPrograms}
                  diffList={diffList}
                  setDiffList={setDiffList}
                />
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div>
              <DetailsDOM details={details} />
              <div className={classes.confirmationContainer}>
                <Typography variant="h4">
                  {t("disconnect_sources.disconnected_mpns")}
                </Typography>
                <MarketingPrograms marketingPrograms={diffList} isDisabled />
              </div>
            </div>
          )}
          {(!currentStep || currentStep < 1 || currentStep > 3) && (
            <div
              className={classes.tableWrapper}
              style={{
                height: 70 * dataSourceResponse.length + 60,
              }}
            >
              <Table
                columns={DisconnectSourcesRequestOutputTable}
                rows={dataSourceResponse}
                tableStyle={classes.myRequestsTable}
              />
            </div>
          )}
        </div>
      </InputFlow>

      {showOutput && (
        <DisconnectSourcesRequestOutputModal
          isOpen={showOutput}
          isLoading={loading}
          requestId={dataSourceResponse[0].requestId}
          data={output}
          setShowOutput={setShowOutput}
        />
      )}

      <Prompt
        message={() => {
          if (isRequestRevisionFlow || isEmpty(sourceSelected)) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={!isInfoStep}
      />
    </>
  );
};

const DetailsDOM = memo(function DetailsDOM({ details }) {
  const classes = useStyles();
  return (
    <>
      {details
        .filter((i) => i.label)
        .map((info) => {
          return (
            <div
              className={clsx(
                classes.flexContainer,
                classes.infoContainer,
                classes.justifyContent
              )}
              key={`${info.label}${info.index}`}
            >
              <Typography variant="h6">{info.label} :</Typography>
              <Typography variant="h6">{info.value}</Typography>
            </div>
          );
        })}
    </>
  );
});

DetailsDOM.propTypes = {
  details: PropTypes.arrayOf().isRequired,
};

export default memo(DisconnectSourcesContainer);
